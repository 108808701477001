import React from 'react';
import { Link } from 'react-router-dom';
import SplashFooter from '../common/footer/SplashFooter';
import SplashHeader from '../common/header/SplashHeader';
import SEO from '../common/SEO';
import SplashBanner from '../component/banner/SplashBanner';
import SplashCta from '../component/cta/SplashCta';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SplashData from '../data/splash/SplashData.json';
import { slugify } from '../utils';
import { FaAngleRight } from "react-icons/fa";

const DemoData = SplashData[2];
const FeatureData = SplashData[1];

const Splash = () => {

    return (
        <>
        <SEO title="Comprehensive Recruitment Agency" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <SplashHeader />
                <SplashBanner />

                <div className="section main-demo-area bg-color-light" id="splash-demo">
                    <div className="container">
                        <div className="section-heading heading-left">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-7">
                                <h2 className="title">What we do</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                DemoData.map((data) => (
                                <div className="col-md-6" key={data.id}>
                                    <div className="single-demo">
                                        {/* <Link to={`${process.env.PUBLIC_URL}/${slugify(data.title)}`}>
                                            <span className="thumb">
                                                <img src={`${process.env.PUBLIC_URL}${data.height_img}`} alt={data.title} />
                                            </span>
                                            <h4 className="title">{data.title}</h4>
                                        </Link> */}
                                        <div>
                                            <span className="thumb">
                                                <img src={`${process.env.PUBLIC_URL}${data.height_img}`} alt={data.title} />
                                            </span>
                                            <h4 className="title">{data.title}</h4>
                                        </div>
                                    </div>
                                </div>
                                ))
                            }

                        </div>
                    </div>
                    <ul className="shape-group list-unstyled">
                        <li className="shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-35.png"} alt="Shape" /></li>
                        <li className="shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="Shape" /></li>
                    </ul>
                </div>

                <div className="section section-padding bg-color-dark splash-features" id="splsh-features">
                    <div className="container">
                        <div className="section-heading heading-light-left">
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                <h2 className="title">About Us</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6 col-md-6" key="1">
                                <div className="services-grid">
                                    <div className="content">
                                        <p>SectorSavvy Talent Solutions is an Information Technology (IT) recruiting company founded by technical individuals who have extensive work experience in the IT field. The founders of SectorSavvy Talent Solutions saw a need for a recruiting company that truly understands the technical skills and experiences required for various IT roles. They recognized that many traditional recruiting firms lack the technical expertise to accurately assess the qualifications and fit of IT candidates.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-md-6" key="1">
                                <div className="services-grid">
                                    <div className="content">
                                        <p>As a result, the founders of SectorSavvy Talent Solutions decided to leverage their own technical knowledge and experience to build a recruiting firm that caters specifically to the IT industry. They wanted to create a company that could effectively match top IT talent with the right opportunities, and help companies build high-performing IT teams. With a deep understanding of the IT field and the ability to evaluate technical skills, SectorSavvy Talent Solutions is uniquely positioned to deliver exceptional results for both job seekers and companies in the IT industry.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul className="list-unstyled shape-group-10">
                        <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                        <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                        <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
                    </ul>
                </div>

                <div className="section section-padding bg-color-light spalsh-why-choose" id="splash-why-choose">
                    <div className="container">
                        <div className="section-heading heading-left">
                            <div className="row align-items-center">
                                <div className="col-xl-6 col-lg-7">
                                    <h2 className="title">Why Us?</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {
                                FeatureData.slice(9, 12).map((data) => (
                                    <div className="col-xl-4 col-lg-6" key={data.id}>
                                        <div className="why-buy-box">
                                            <div className="heading">
                                                <div className="icon">
                                                    <img src={process.env.PUBLIC_URL + data.icon} alt="Thumb" />
                                                </div>
                                                <h5 className="title" dangerouslySetInnerHTML={{__html: data.title}}></h5>
                                            </div>
                                            <p>{data.para}</p>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="line-shape">
                        <img src={process.env.PUBLIC_URL + "/images/others/line-6.png"} alt="Line" />
                    </div>
                </div>
                <SplashCta />
                <SplashFooter />
            </main>
        </>
    )
}

export default Splash;