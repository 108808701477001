import React from 'react';
import Logo from '../../elements/logo/Logo';
import StickyHeader from './StickyHeader';


const SplashHeader = () => {

    const sticky = StickyHeader(100);

    return (
        <header className="header ssts-header header-style-1 splash-header-style">
            <div className={`ssts-mainmenu ${sticky ? "ssts-sticky" : ""}`}>
                <div className="container">
                <div className="header-navbar">
                    <div className="header-logo">
                        <Logo />
                    </div>
                    <div className="header-main-nav">
                    {/* Start Mainmanu Nav */}
                    <nav className="mainmenu-nav">
                        <ul className="mainmenu">
                            <li><a href="/#splash-demo">What we do</a></li>
                            <li><a href="/#splsh-features">About Us</a></li>
                            <li><a href="/#splash-why-choose">Why Us</a></li>
                            <li><a href="/blog">Blog</a></li>
                        </ul>
                    </nav>
                    {/* End Mainmanu Nav */}
                    </div>
                    <div className="header-action">
                    <ul className="list-unstyled">
                        <li className="buy-btn"><a target="_blank" rel="noopener noreferrer" href="mailto:info@sectorsavvytalent.com" className="ssts-btn btn-fill-primary">Contact Us!</a></li>
                    </ul>
                    </div>
                </div>
                </div>
            </div>
        </header>

    )
}

export default SplashHeader;