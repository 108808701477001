import React from 'react';
import { Link } from 'react-router-dom';


const WrappedLogo = ({className, src}) => {
    return (
        <img width={150} height={150} className={className} src={src} alt="logo"/>
    )
}
const Logo = ({limage, dimage, simage}) => {
    return (
        <Link to={process.env.PUBLIC_URL + "/"}>
            <WrappedLogo className="light-version-logo" src={process.env.PUBLIC_URL + '/images/ssts-black.svg'} />
            <WrappedLogo className="dark-version-logo" src={process.env.PUBLIC_URL + '/images/ssts-white.svg'} />
            <WrappedLogo className="sticky-logo" src={process.env.PUBLIC_URL + '/images/ssts-black.svg'} />
        </Link>
    )
}


export default Logo;